@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    --star-size: 18px;
    --star-color: #b1b1b1;
    --star-background: #fc0;
    --bg-primary: #241763;
    --bg-second: #473d78 !important;
    --background-color: #e7e6ed;
    --text-light-black: #2b2b2b;
    --text-light-gray: #8c8c8c;
    --input-placeholder-color: #7a7a7a;
    --input-border-color: #e1e1e1;
    --text-color: #463c77;
    --bg-primary: #241763
}

.font-poppins,
h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: PoppinsReg !important
}
.btn-outline-primary{
    border: 1px solid #241763 !important;
    color: #241763 !important;
}
.btn-outline-primary:hover{
    background-color: #241763 !important;
    color: #fff !important;
}
.progressWrapper {
  max-width: 100%;
  /* width: 500px; */
  margin-bottom: 40px;
  position: relative;

  display: flex;
  justify-content: space-between;
}

.progressWrapper::before {
  content: "";
  width: 100%;
  height: 4px;

  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  z-index: -1;
  transition: 0.3s ease-in-out;
  background-color: #e6e6e6;
}

.progressNumbering {
  width: 0;
  height: 4px;

  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  z-index: -1;
  transition: 0.3s ease-in-out;
  background-color: #241763;
}

.stepNumber {
  width: 40px;
  height: 40px;
  font-weight: bold;

  display: flex;
  align-items: center;
  justify-content: center;
  color: #333;
  background-color: #fff;
  border: 3px solid #e6e6e6;
  border-radius: 50%;
  transition: 0.3s ease-in-out;
}

.stepNumber.active {
  border-color: #241763;
}

.sign-in-forgot,
a {
    text-decoration: none
}
.customSidebarScroll,
.linksWrapper,
.linksWrapperMiniSidebar {
    overflow-x: hidden;
    overflow-y: auto;
}

.customSidebarScroll::-webkit-scrollbar,
.linksWrapper::-webkit-scrollbar,
.linksWrapperMiniSidebar::-webkit-scrollbar {
    width: 0;
    transition: width 0.3s;
}

.customSidebarScroll:hover::-webkit-scrollbar,
.linksWrapper:hover::-webkit-scrollbar,
.linksWrapperMiniSidebar:hover::-webkit-scrollbar {
    width: 5px;
}

.customSidebarScroll::-webkit-scrollbar-track,
.linksWrapper::-webkit-scrollbar-track,
.linksWrapperMiniSidebar::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.customSidebarScroll::-webkit-scrollbar-thumb,
.linksWrapper::-webkit-scrollbar-thumb,
.linksWrapperMiniSidebar::-webkit-scrollbar-thumb {
    background: #888;
}

.customSidebarScroll::-webkit-scrollbar-thumb:hover,
.linksWrapper::-webkit-scrollbar-thumb:hover,
.linksWrapperMiniSidebar::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.btnWithIcon,
.chatTimeStampsent,
.userSection {
    white-space: nowrap
}

.childChip span,
.childChipActive span {
    font-family: poppins;
    font-style: italic
}

.Report_style,
.Salary_Data,
.Salary_Heading,
.Year_table,
.seven-class,
.test {
    text-align: center
}

.image--cover,
.imageCoverNav {
    object-position: center right
}


@font-face {
    font-family: PoppinsReg;
    src: url("./assets/Poppins-Regular.ttf")
}

@font-face {
    font-family: PoppinsBold;
    src: url("./assets/Poppins-Bold.ttf")
}

@font-face {
    font-family: PoppinsLight;
    src: url("./assets/Poppins-Light.ttf")
}

@font-face {
    font-family: PoppinsSemiBold;
    src: url("./assets/Poppins-SemiBold.ttf")
}


.media-options-popup {
    position: absolute;
    bottom: 100%;
    right: 0;
    background: white;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0,0,0,0.1);
    padding: 8px 0;
    min-width: 180px;
    z-index: 1000;
  }
  
  .option-item {
    padding: 8px 16px;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  .option-item:hover {
    background-color: #f5f5f5;
  }
.bg-main,
.bgMain,
.custom-checkbox .custom-control-input:active~.custom-control-label::before {
    background-color: var(--bg-primary)
}

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: PoppinsReg;
    user-select: none
}

.text-primary {
    color: #241763 !important
}

.font-poppins {
    font-weight: 500
}

.poppins-regular,
p,
span {
    font-family: PoppinsReg
}

.chatFilterDropdownLists,
.font-size13,
.font13px,
.observationText,
.optionLists span,
.starFilter .ratingPoint,
.starFilter .totalRatings {
    font-size: 13px
}

.pr-0 {
    padding-right: 0 !important
}

body {
    background-color: #f8f8f8
}

a {
    color: #000
}

.form-label {
    margin-bottom: .2rem
}

.chatHeader .chatUserName,
.chatbotHeader h6,
.classManagementDate,
.courseDescription,
.filterItemsWrapper .filterTitle,
.font-size14,
.formTeacherRep,
.message-content,
.userClassMeta h6 {
    font-size: 14px
}

.reviewForm {
    font-size: .7rem
}

.btn-primary {
    --bs-btn-color: #fff;
    --bs-btn-border-color: var(--bg-primary) !important;
    --bs-btn-bg: var(--bg-primary) !important;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: var(--bg-primary);
    --bs-btn-hover-border-color: var(--bg-primary);
    --bs-btn-focus-shadow-rgb: 49, 132, 253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: var(--bg-primary);
    --bs-btn-active-border-color: var(--bg-primary);
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: var(--bg-primary);
    --bs-btn-disabled-border-color: var(--bg-primary)
}

.btn-check:checked+.btn:focus-visible,
.btn.active:focus-visible,
.btn.show:focus-visible,
.btn:first-child:active:focus-visible,
:not(.btn-check)+.btn:active:focus-visible {
    box-shadow: #c5bbfa
}

.form-check-input:focus {
    border-color: #493f7b;
    box-shadow: 0 0 0 .25rem rgba(73, 63, 123, .25)
}

.form-control:focus,
.form-select:focus {
    border-color: none !important;
    border: 1px solid #e4e7eb;
    box-shadow: none
}

.welcomeBox {
    background-color: rgba(214, 212, 225, .5);
    padding: 30px;
    border-radius: 20px
}

.logoWithText,
.welcomeTilles {
    gap: 10px
}

.logoWithText .userIcons {
    width: 110px;
    height: 110px;
    background-color: #fff;
    border-radius: 100%;
    border: 7px solid #ededf2
}

.welcomeTxtP h6 {
    color: #241763;
    font-weight: 700;
    font-size: 28px
}

.welcomeTxtP p {
    font-weight: 400;
    font-size: 16px;
    color: #7a7a7a
}

.courseTiles {
    background-color: var(--bg-primary);
    border-radius: 16.05px;
    display: flex;
    justify-content: space-between;
    padding: 10px;
    align-items: center;
    width: 170px
}

.tilesText h6 {
    font-weight: 400;
    font-size: 14px;
    color: #fff
}

.tilesText p {
    font-weight: 600;
    font-size: 22px;
    color: #fff
}

.heading24px {
    font-size: 24px;
    color: #241763
}

.myClasses h3 {
    font-weight: 600;
    font-size: 24px
}

.myClassesHeading {
    color: #241763;
    font-weight: 600;
    font-size: 24px
}

.heading-class,
.relative .shadow-lg,
.truncate {
    color: #241763
}

.courseBox {
    background-color: #fff;
    border-radius: 10px;
    padding: 10px;
    gap: 10px;
    margin-top: 10px
}

.courseDetalsTxt h6 {
    font-size: 18px;
    font-weight: 600
}

.courseDetalsTxt p {
    font-size: 14px;
    font-weight: 400;
    color: #7a7a7a
}

.thubnailCourse {
    width: 200px;
    height: 130px;
    position: relative;
    display: inline-block
}

.courseDetails,
.react-datepicker-wrapper,
.wrap-table100,
table {
    width: 100%
}

.thubnailCourse img {
    border-radius: 7px;
    width: 100%;
    height: 100%;
    display: block
}

.poppins-bold {
    font-family: PoppinsBold
}

.poppins-semibold {
    font-family: PoppinsSemiBold
}

.logo_img {
    position: absolute;
    top: 20px;
    left: 70px;
    right: 0;
    margin: auto;
    z-index: 1
}

.signin_page {
    background-color: var(--text-color);
    background-image: url("./assets/images/bg.png");
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    height: 100vh;
    overflow: hidden
}

.signin_page::after {
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(255, 255, 255, .6);
    z-index: -1
}

.sign_in_box2 {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh
}

.sign_in_heading {
    color: var(--text-light-black)
}

.forgot_paragraph,
.reset_psw_paragraph,
.sign-in-toggle,
.sign_in_paragraph {
    color: var(--text-light-gray)
}

.sign_in_paragraph {
    width: 95%
}

.container .sign_in_input {
    display: block;
    width: 100%;
    padding: .375rem 1.2rem;
    font-size: 1rem;
    font-weight: 400;
    height: 45px;
    border-radius: 7px;
    border: 1px solid var(--input-border-color)
}

.container .sign_in_input:active,
.container .sign_in_input:focus {
    display: block;
    width: 100%;
    padding: .375rem 1.2rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    border: 1px solid var(--input-border-color);
    outline: 0;
    box-shadow: 0 0 0 0 #fff
}

input::placeholder {
    color: input-placeholder-color !important
}

.password-input {
    padding-right: 40px;
    border-radius: 7px
}

.input-group {
    border-radius: 7px;
    background-color: #ff0
}

.password-toggle-btn {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    border: none;
    background: #fff;
    cursor: pointer
}

.inner-circle,
.percent {
    left: 50%;
    transform: translate(-50%, -50%)
}

.input-group:not(.has-validation)>.dropdown-toggle:nth-last-child(n+3),
.input-group:not(.has-validation)>.form-floating:not(:last-child)>.form-control,
.input-group:not(.has-validation)>.form-floating:not(:last-child)>.form-select,
.input-group:not(.has-validation)>:not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating) {
    border-top-right-radius: 7px !important;
    border-bottom-right-radius: 7px !important
}

.sign_in_btn,
.sign_in_btn:hover {
    border: 2px solid var(--text-color)
}

.form-switch .form-check-input {
    width: 45px;
    height: 25px
}

.circle-checkbox.active .inner-circle,
.form-check-input:checked {
    background-color: var(--text-color)
}

.form-check-input:checked+.sign-in-toggle::before,
.second {
    background-color: #fff
}

.form-check-input:checked+.sign-in-toggle::after {
    background-color: gray
}

.form-switch .form-check-label {
    font-size: 1.2rem
}

.main_box.active .main p,
.sign-in-forgot,
.sign_in_btn:hover {
    color: var(--text-color)
}

@media screen and (max-width:576px) {
    .logo_img {
        position: absolute;
        top: 20px;
        left: 0;
        text-align: center;
        right: 0;
        margin: auto;
        z-index: 1
    }

    .form-switch .form-check-input {
        width: 25px;
        height: 14px
    }

    .sign-in-toggle {
        font-size: 11px
    }

    .sign-in-forgot {
        font-size: 12px
    }
}

.sign-in-forgot {
    cursor: pointer !important
}

.sign_in_btn {
    background-color: var(--text-color);
    color: #fff
}

.main_box {
    border: 2px solid var(--text-light-gray);
    color: var(--text-light-gray);
    cursor: pointer;
    border-radius: 7px;
    width: 100%;
    padding: .53rem 1.2rem
}

.main svg path {
    stroke: var(--text-light-gray)
}

.main_box.active,
.main_box:hover {
    border: 2px solid var(--text-color);
    color: var(--text-color)
}

.main_box.active svg path,
.main_box:hover svg path {
    stroke: var(--text-color)
}

.circle-checkbox {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid var(--text-light-gray);
    position: relative
}

.circle-checkbox.active {
    border: 2px solid var(--text-color)
}

.inner-circle {
    width: 10px;
    height: 10px;
    background-color: transparent;
    border-radius: 50%;
    position: absolute;
    top: 50%
}

.otp_error_msg {
    color: red
}

.thubnailCourse span {
    position: absolute;
    top: 5px;
    left: 5px;
    background-color: #fffa7d;
    padding: 2px 10px;
    border-radius: 7px;
    font-size: 12px;
    font-weight: 500
}

.progress {
    margin: 15px 0;
    padding: 0;
    width: 90%;
    height: 10px;
    overflow: hidden;
    background: #e5e5e5;
    border-radius: 6px
}

.bar {
    position: relative;
    float: left;
    min-width: 1%;
    height: 100%;
    background: var(--bg-primary)
}

.percent {
    position: absolute;
    top: 50%;
    margin: 0;
    font-family: tahoma, arial, helvetica;
    font-size: 12px;
    color: #fff
}

.lctDuration {
    display: flex;
    justify-content: flex-start;
    gap: 5px;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
    color: #767278
}

.lctDuration p {
    margin-bottom: unset
}

.continueBtn {
    height: 40px
}

.taskListView {
    background-color: #fff;
    padding: 30px;
    border-radius: 15px;
    height: inherit
}

.taskImg {
    width: 50px;
    height: 50px;
    border-radius: 10px
}

.taskListView h4 {
    color: #241763;
    font-size: 20px;
    font-weight: 600
}

.taskDetails h6 {
    font-size: 15px;
    font-weight: 500
}

.taskDetails p {
    font-size: 12px;
    font-weight: 400;
    color: #767278
}

.meachineClr {
    background-color: #e9edff
}

.discussionClr {
    background-color: #ffefe0
}

.quizClr {
    background-color: #eae7ff
}

.taskClr {
    background-color: #fff6d8
}

.taskListView div {
    gap: 20px;
    /* margin-bottom: 10px */
}

.dashboardWrapper {
    gap: 20px
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0
}

.react-datepicker-popper {
    left: 20px !important
}

.avatarBackdrop span,
.donotPercentage {
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 99999
}

.text-xs,
.userClassMeta p {
    font-size: 11px
}

.text-gray {
    background-color: #ececec !important
}

.text-gray-font {
    color: #ececec !important
}

.dotGraph {
    height: 8px;
    width: 8px;
    background-color: var(--bg-primary);
    border-radius: 50%
}

.donotPercentage {
    position: absolute;
    top: 43%;
    color: #000;
    font-weight: 700
}

.donotMatrics {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 4px 15px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    align-content: center;
    width: 100%
}

.sidebarOuterLg,
.sidebarOuterSm {
    position: relative;
    background-color: transparent
}

.subjectColor {
    height: 12px;
    width: 40px;
    border-radius: 5px
}

.divider {
    height: 2px !important
}

.fg-main {
    color: var(--bg-primary)
}

.bgInActive {
    background-color: #ebebeb
}

.cursor-pointer {
    cursor: pointer !important
}

.customShadow {
    box-shadow: rgba(100, 100, 111, .2) 0 7px 29px 0
}

::-webkit-scrollbar-track {
    border-radius: 0
}

::-webkit-scrollbar-thumb {
    border-radius: 0
}

.borderRadius_15,
.libraryCard,
.rounded-md,
.sidebarOuterLg,
.sidebarOuterSm {
    border-radius: 15px
}

.bookDetailsModalHeader,
.chipBg {
    background-color: #edebf1
}

.customSidebarScroll {
    max-height: 100vh;
    scrollbar-width: thin;
    scrollbar-color: var(--bg-primary)
}

.customSidebarScroll::-webkit-scrollbar {
    width: 6px !important;
    border-radius: 8px
}

.customSidebarScroll::-webkit-scrollbar-thumb {
    background-color: var(--bg-primary);
    border-radius: 12px
}

.customSidebarScroll::-webkit-scrollbar-track {
    background-color: var(--bg-primary);
    border-radius: 4%
}

.linksScroll {
    overflow-y: auto;
    height: 100%;
    max-height: 100%
}

.sidebarOuterLg {
    height: 100vh;
    width: 22%;
    transition: width .2s linear
}

.sidebarOuterSm,
.sidebarWrapperSm {
    height: 98.9vh;
    transition: width .2s linear
}

.sidebarOuterSm {
    width: 8%
}

.sidebarWrapperLg,
.sidebarWrapperSm,
.smSidebarWrapper {
    margin: 5px;
    border-radius: 15px;
    background-color: var(--bg-primary);
    display: flex;
    gap: 20px;
    padding: 40px 8px
}

.sidebarWrapperSm {
    width: 6%;
    flex-direction: column
}

.sidebarWrapperLg {
    height: 98%;
    width: 17%;
    flex-direction: column;
    transition: width .2s linear
}

.smSidebarWrapper {
    position: relative;
    height: 98.9vh;
    width: 5%;
    flex-direction: column
}

.sidebarLink,
.sidebarLinkActive {
    border-radius: 7px;
    cursor: pointer;
    color: #fff
}

.sidebarLogo img {
    width: 143px;
    height: 81px;
    max-width: 90%;
    object-fit: contain
}

.divider {
    background-color: #979797b8;
    width: 100%
}

.sidebarLink:hover,
.sidebarLinkActive {
    background-color: #585085;
    margin-right: 5px
}

.linksWrapper {
    height: 100%
}

.linksWrapperMiniSidebar {
    height: 77vh
}

.sidebarLink {
    padding: 10px
}

.sidebarLinkActive {
    padding: 10px !important
}

.sidebarToggle,
.sidebarToggleLeft {
    height: 30px;
    background-color: var(--bg-primary);
    position: absolute;
    top: 127px;
    width: 30px;
    color: #fff;
    display: flex;
    cursor: pointer;
    box-shadow: -6px 0 22px 0 #191e4566
}

.sidebarToggle {
    right: -14px;
    justify-content: centere;
    align-items: center;
    border: 1px solid var(--bg-primary);
    border-radius: 7px
}

.sidebarToggleLeft {
    left: -14px;
    justify-content: centere;
    align-items: center;
    border: 1px solid var(--bg-primary);
    border-radius: 7px;
    rotate: 180deg
}

.sidebarLinks {
    overflow-y: scroll
}

.navbarWrapper {
    padding: 15px;
    width: 100%;
    background: #fff
}

.searchBox {
    height: 38px;
    border: 1px solid #5f5f5f4a;
    border-radius: 6px;
    background-color: #fff;
    width: 35%;
    cursor: pointer
}

.menuItems,
.routeName,
.searchBoxWrapper {
    height: 100%
}

.searchBox svg {
    margin: 10px
}

.routeName {
    width: 22%
}

.menuItems {
    width: 40%;
    flex-grow: 1
}

.navbarWrapper .searchBox input {
    outline: 0;
    border: none
}

.chip,
.navAvatar,
.userAvatar {
    border-radius: 50%
}

.chip {
    height: 16px;
    width: 16px;
    background-color: #f93c65;
    top: -12px;
    right: -4px;
    padding: 10px
}

.navAvatar {
    height: 50px;
    width: 50px;
    background-color: #ebebeb
}

.closeIconWrapper,
.miniSidebar {
    background-color: var(--bg-primary);
    position: absolute
}

.flagsDropdown {
    padding: 5px 10px;
    right: -4px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 5px;
    height: 120px;
    border-radius: 7px
}

.flagList {
    border-radius: 6px;
    gap: 5px
}

.setLanguage img {
    width: 30px;
    height: 20px
}

.routesWrapper {
    padding: 15px
}

.assesmentsWrapper {
    padding: 0
}

.miniSidebar {
    height: 100vh;
    max-height: 100vh;
    width: 0;
    overflow: hidden;
    transition: width .5s;
    z-index: 999
}

.coursesWrapper,
.miniSidebar.open {
    width: 80%
}

.miniSidebar.closed {
    width: 0
}

.hide {
    transform: translateX(-50%);
    transition: .2s linear
}

.closeIconWrapper {
    height: 30px;
    width: 30px;
    right: -9px;
    top: 87px;
    display: flex;
    justify-content: centere;
    align-items: center;
    color: #fff;
    border: 1px solid #605888;
    box-shadow: -6px 0 22px 0 #191e4566;
    border-radius: 7px;
    cursor: pointer
}

.filterDropdown {
    padding: 5px 10px;
    border: 1px solid #1b1b1b38;
    border-radius: 6px;
    background-color: #fff;
    font-size: 14px
}

.courseChip {
    padding: 4px 10px;
    border-radius: 16px;
    height: auto;
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10px
}

.courseInstructor {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    gap: 10px
}

.courseInstructor h4 {
    font-size: 14px;
    color: --bg-primary;
    color: var(--bg-primary)
}

.courseInstructor h5 {
    color: gray;
    font-size: 14px
}

.courseInstructor .userIcon {
    height: 14px;
    width: 14px
}

.salesPrice {
    font-size: 22px;
    color: var(--bg-primary)
}

.normalPrice {
    font-size: 15px;
    text-decoration: line-through;
    color: #1b1b1b99
}

.courseLists {
    display: flex;
    flex-wrap: wrap
}

.btn,
.iconWrapper {
    justify-content: center
}

.courseCard {
    border-radius: 15px;
    background-color: #fff
}

.filterSmSCreen {
    position: fixed;
    display: block !important;
    top: 0;
    left: 0;
    min-height: 100vh;
    overflow-y: auto;
    width: 100%;
    background: #fff;
    padding: 10px;
    z-index: 9999
}

.filterItemsWrapper label,
.totalViewsRating {
    font-size: 13px !important
}

.video-dot {
    font-size: 14px;
    color: #1b1b1b99
}

.video-time {
    color: --bg-primary;
    font-size: 14px
}

.colored-data {
    background-color: #f9f9f9e5
}

.colored-heading {
    color: #1b1b1b99
}

.icon {
    cursor: pointer;
    color: #1b1b1b1a
}

.icon-time {
    color: #1b1b1b1a;
    font-size: 14px
}

.colored-time {
    color: --bg-primary;
    font-size: 14px;
    margin-bottom: 10px
}

.colored-time-2 {
    color: #44ab96;
    font-size: 14px;
    margin-bottom: 10px
}

.playing {
    background-color: --bg-primary
}

.completed {
    background-color: #44ab96
}

.viser-helper {
    color: --bg-primary
}

.digital-studio {
    position: relative;
    bottom: 7px;
    font-size: 12px
}

.last-text {
    color: #1b1b1b99;
    font-size: 12px
}

.first-text {
    position: relative;
    bottom: 15px;
    color: #1b1b1b99
}

.button {
    width: 230px;
    border-radius: 10px;
    border: 1px solid var(--bg-primary);
    background-color: #fff;
    padding: 10px;
    color: var(--bg-primary);
    font-weight: 700
}

.assesmentWrapper .btnSubmit,
.instructionBox {
    background-color: var(--bg-primary);
    color: #fff
}

.btn {
    display: flex;
    align-items: center;
    border: 0
}

.instructionBox {
    padding: 50px
}

.instructionBox h4 {
    font-size: 40px;
    font-weight: 700;
    padding-bottom: 15px;
    text-align: start
}

.instructionBox ul {
    list-style-type: none;
    padding: 0 !important;
    margin: 0 !important;
    display: flex;
    flex-direction: column;
    gap: 15px
}

.question,
.selectedQuestion {
    padding: 12px;
    gap: 5px;
    align-items: center;
    display: flex;
    cursor: pointer
}

.instructionBox ul li {
    font-size: 12px;
    font-weight: 300;
    color: #f0f0f0
}

.instructionBox button {
    width: 100%;
    margin: 20px 0;
    padding: 15px 5px;
    border: none;
    border-radius: 8px
}

.assesmentWrapper {
    background-color: var(--bg-primary);
    min-height: 100vh
}

.progressBarOuter {
    background-color: #6c6491 !important
}

.dotWhite,
.progressBarInner {
    background-color: #fff !important
}

.question {
    border-radius: 8px;
    border: 1px solid #e0e0e0;
    background-color: #f9f9f9
}

.selectedQuestion {
    border-radius: 8px;
    border: 1px solid #e0e0e0;
    background-color: #f4f0ff;
    width: 100%
}

.assesmentWrapper .btnSubmit {
    width: 100%;
    padding: 15px 5px;
    border: none;
    border-radius: 8px
}

.btnCancel,
.btnNext {
    border: none;
    font-weight: 700
}

.questionImageWrapper {
    height: 350px;
    width: 100%
}

.btnCancel {
    background-color: #edebf1;
    color: var(--bg-primary)
}

.btnNext,
.observationHeader {
    background-color: var(--bg-primary);
    color: #fff
}

.observationHeader {
    border-top-right-radius: 7px;
    height: 50px;
    border-top-left-radius: 7px;
    display: flex;
    align-items: center
}

.observationContent {
    height: auto;
    max-height: 60vh;
    overflow-y: auto
}

.gradesTable thead {
    border-bottom: 1px solid #d1cccc
}

.gradesTable {
    min-height: 80vh
}

.popupWrapper {
    position: absolute;
    display: flex;
    padding: 8px 5px;
    box-shadow: -6px 0 22px 0 #191e4566;
    background: #fff;
    flex-direction: column;
    gap: 2px;
    width: 120px;
    height: auto;
    z-index: 100;
    border-radius: 8px
}

.optionLists:hover {
    background-color: #66666626
}

.iconWrapper {
    height: 40px;
    width: 40px;
    border-radius: 6px;
    background-color: #fff;
    display: flex;
    align-items: center
}

.counterIconWrapper,
.examChip {
    justify-content: center;
    display: flex
}

.gradeTableDropdown {
    position: absolute;
    right: 0;
    top: 40px
}

.iconWithBg {
    background-color: #6f6795;
    padding: 5px
}

.examChip {
    padding: 5px;
    align-items: center;
    border-radius: 7px;
    font-size: 10px
}

.examChipsWrapper>:first-child,
.examChipsWrapper>:nth-child(3) {
    background-color: #e4effc;
    color: #3d87dc
}

.examChipsWrapper>:nth-child(2) {
    background-color: #e5f3f2;
    color: #30ada1
}

.examSvgsText span {
    color: #fff;
    text-transform: capitalize;
    font-size: 10px
}

.examSvgsText p {
    font-size: 10px;
    font-weight: 500;
    color: #fff
}

.examDataWrapper {
    box-shadow: 0 11px 24px 0 #7676762e;
    border-radius: 8px;
    background: radial-gradient(circle, #585085, #241763);
    color: #fff
}

.examModalWrapper {
    border-radius: 22px
}

.examModalHeader {
    height: 50px;
    background-color: #edebf1;
    border-radius: 20px 20px 0 0
}

.examModalHeader p,
h4 {
    padding: 0;
    margin: 0
}

.examModalHeader .iconWrapper {
    width: 38px;
    height: 38px;
    border-radius: 10px;
    background-color: var(--bg-primary);
    color: #fff
}

.Examcounter {
    height: 35px;
    display: flex;
    width: 50%;
    background-color: #fff;
    border-radius: 8px;
    padding: 0 6px;
    justify-content: space-between;
    border: 1px solid #bab6c8;
    align-items: center
}

.counterIconWrapper {
    height: 30px;
    width: 30px;
    align-items: center;
    background-color: #ebebeb;
    border-radius: 10px;
    border: 1px solid #bab6c8;
    cursor: pointer
}

.btnFooter {
    width: 100%;
    padding: 5px 25px;
    border-radius: 6px;
    border: none
}

.RecievedMessageBubble,
.chatBubbleSent,
.chatbotHeader p,
.modalWrapper input,
.modalWrapper label,
.navLabel,
.question label,
.selectedQuestion label {
    font-size: 12px
}

.modalWrapper input:focus-within,
.modalWrapper select:focus-within {
    outline: 0 !important;
    box-shadow: none !important;
    border: 1px solid #e6e6e6
}

.modalWrapper textarea:focus-within {
    outline: 0 !important;
    box-shadow: none !important;
    border: 1px solid #e6e6e6
}

#classSelect option:hover {
    background-color: red !important
}

.ReactModal__Overlay {
    background-color: rgb(113 110 110 / 75%) !important;
    z-index: 100
}

.ReactModal__Content {
    background: #fff;
    opacity: 100 !important;
    width: 60% !important;
    padding: 0 !important;
    box-shadow: 0 8px 15px 0 #35353524;
    border-radius: 20px !important;
    overflow-x: hidden !important
}

.addQuestionIconWrapper {
    height: 30px;
    width: 30px;
    border-radius: 10px;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center
}

.userAvatar {
    height: 40px;
    width: 40px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #fff
}

.addParticipantBtn,
.questionDotOuter {
    align-items: center;
    display: flex;
    cursor: pointer
}

.userAvatar img {
    border-radius: 50%
}

.addParticipantBtn {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    justify-content: center;
    border: 1px dotted #463c77;
    background-color: #c4afff4d
}

.locationInput {
    border: none !important
}

.userAvatars {
    display: flex;
    gap: -5px;
    position: relative
}

.userAvatars .userAvatar.lists {
    margin-left: -15px
}

.userAvatars .userAvatar.mask {
    margin-left: -15px !important;
    filter: blur(.3)
}

.avatarBackdrop {
    position: absolute;
    background-color: #283b43;
    height: 100%;
    width: 100%;
    opacity: .7
}

.avatarBackdrop span {
    position: absolute;
    top: 50%;
    color: #fff;
    font-size: 12px
}

.userAvatars .userAvatar {
    height: 32px;
    width: 32px
}

.questionBarProgress {
    width: 100%;
    height: 8px;
    border-radius: 15px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 3px;
    gap: 4px;
    align-items: center;
    position: relative;
    background-color: #e0e0e0;
    overflow: hidden
}

.questionBarProgress .dot {
    height: 4.68px;
    width: 4.68px;
    border-radius: 50%;
    background-color: #51477f;
    position: relative;
    z-index: 9999
}

.questionDotOuter {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    justify-content: center;
    border: 1px solid var(--bg-primary);
    padding: 2px
}

.questionDotInner {
    height: 100%;
    width: 100%;
    border-radius: 50%;
    background-color: var(--bg-primary)
}

.questionBarProgress .progressBar {
    position: absolute;
    background-color: #51477f;
    height: 100%;
    border-radius: 15px;
    left: 0
}

.communityWrapper {
    box-shadow: 0 11px 24px 0 #8a89892b;
    background-color: #efefef
}

.singleChatWrapper {
    height: 83vh;
    border-radius: 15px
}

.ChatsWrapper {
    height: 100%;
    border-radius: 15px
}

.messageWrapper {
    height: 90%;
    max-height: 90%;
    overflow-y: auto;
    overflow-x: hidden;
    margin-bottom: 15px
}

.SearchWrapper {
    width: 100%;
    height: 34px;
    border-radius: 7.4px;
    background-color: #edebf1c9;
    padding: 2px 10px;
    display: flex;
    gap: 2px;
    align-items: center
}

.nameAvatar,
.textChip {
    justify-content: center
}

.SearchWrapper input[type=text] {
    outline: 0;
    border: none;
    border-radius: 7.4px;
    color: var(--bg-primary) 63;
    width: 90%;
    background-color: transparent;
    font-size: 12px
}

.SearchWrapper .searchIcon {
    height: 20px;
    width: 20px;
    color: var(--bg-primary)
}

.chatWrapper img {
    height: 38px;
    width: 37px;
    min-width: 37px;
    border-radius: 7.2px;
    object-fit: cover
}

.chatWrapper .userName {
    font-size: 12px !important;
    font-weight: 700
}

.textChip {
    padding: 2px 5px;
    font-weight: 500;
    display: flex;
    align-items: center;
    border-radius: 7px;
    border: none;
    font-size: 10px
}

.chatWrapper .message {
    padding: 0;
    margin: 0;
    font-size: 11px;
    max-width: 96%;
    overflow: hidden;
    max-height: 32px;
    background-color: #fff
}

.chatbox .chats,
.messages {
    overflow-y: auto;
    overflow-x: hidden
}

.chatWrapper .chatFooter {
    font-size: 9px !important
}

.chatFooter .icon {
    height: 13px;
    width: 13px;
    color: #9fa7be
}

.chatWrapper {
    border-top: .1px solid #afb8cf !important;
    padding: 10px 15px
}

.customDivider {
    background-color: #afb8cf;
    padding: .1px 0
}

.customDropdown {
    background-color: transparent;
    border: none !important
}

.chatHeader {
    height: 10%;
    background-color: var(--bg-primary);
    color: #fff;
    align-items: center;
    padding: 0 15px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px
}

.chatHeader .userDp {
    height: 38px;
    width: 37px;
    min-width: 37px;
    border-radius: 7.2px;
    object-fit: cover;
    border: 1px solid #fff;
    padding: 1px
}

.messages {
    height: 90%;
    max-height: 90%;
    padding: 0 15px
}

.chatFooterMain {
    height: 10%;
    background-color: #edebf1;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    display: flex;
    align-items: center;
    border: 1px solid #d4d1df;
    padding: 0 15px;
    color: #fff;
    gap: 5px
}

.nameAvatar,
.roundedNameAvatar {
    display: flex;
    background-color: #f1eff4;
    color: var(--bg-primary);
    font-weight: 700;
    text-transform: uppercase
}

.nameAvatar {
    width: 37px;
    height: 37px;
    border-radius: 7.24px;
    border: .5px solid var(--bg-primary) 80;
    align-items: center
}

.roundedNameAvatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: .5px solid var(--bg-primary) 80;
    justify-content: center;
    align-items: center;
    font-size: 11px
}

.addFileChatWrapper,
.emojiWrapper {
    background-color: var(--bg-primary);
    border-radius: 50%;
    display: flex
}

.emojiWrapper {
    align-items: center;
    justify-content: center;
    padding: 8px;
    color: #fff !important
}

.addFileChatWrapper {
    color: #fff;
    height: 25px;
    width: 25px;
    justify-content: center;
    align-items: center
}

.chatFooterMain input {
    border: none;
    outline: 0;
    width: 100%
}

.chatInputWrapper {
    border: 1px solid #d4d1df;
    height: 40px;
    background-color: #fff;
    border-radius: 24px;
    padding: 0 10px;
    display: flex;
    align-items: center;
    justify-content: space-between
}

.chat-box {
    display: flex;
    flex-direction: column
}

.message {
    max-width: 70%;
    margin-bottom: 10px;
    padding: 8px;
    border-radius: 8px
}

.RecievedMessageBubble,
.chatBubbleSent {
    height: auto;
    padding: 15px 10px;
    border-top-right-radius: 15px;
    border-top-left-radius: 15px
}

.sent {
    align-self: flex-end;
    background-color: #dcf8c6
}

.received {
    align-self: flex-start;
    background-color: #e2e2e2
}

.message-timestamp {
    font-size: 12px;
    color: #777
}

.sentMessageBubble {
    display: flex;
    flex-direction: column;
    gap: 2px
}

.chatBubbleSent {
    background-color: var(--bg-primary);
    border-bottom-left-radius: 15px;
    color: #fff
}

.RecievedMessageBubble {
    background-color: #edebf1 !important;
    border-bottom-right-radius: 15px;
    color: #000
}

.chatTimeStamprecieved,
.chatTimeStampsent {
    color: #afb8cf;
    font-size: 12px
}

.dropdown-toggle::after {
    content: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-chevron-down"><path d="m6 9 6 6 6-6"/></svg>');
    position: relative;
    top: 4px;
    border: none !important;
    vertical-align: 0 !important
}

.chatTimeStampsent {
    position: absolute;
    right: 2px;
    top: 100%
}

.chatbotIconWrapper,
.chatbox {
    right: 20px;
    position: fixed;
    display: flex
}

.chatsMain {
    width: 35%;
    height: 83vh
}

.chatFilterDropdownLists:hover {
    background-color: #edebf1;
    border-radius: 6px
}

.bookDetailsFooter .btnDownload,
.chatClosed,
.chatOpened:hover {
    background-color: var(--bg-primary)
}

.libraryCard .libraryImageWrapper {
    height: 250px;
    width: 100%
}

.libraryImageWrapper img {
    height: 100%;
    width: 100%;
    border-radius: 10px
}

.authorSection {
    color: #7a7a7a;
    font-size: 12px
}

.bookDetails label {
    font-size: 12px;
    color: #7a7a7a
}

.bookModelDescription {
    height: 120px;
    max-height: 120px;
    white-space: wrap;
    overflow: hidden;
    font-size: 14px;
    color: #7a7a7a
}

.bookDetailsFooter .btnDownload {
    width: auto;
    padding: 10px 20px;
    color: #fff;
    border-radius: 6px;
    border: none;
    font-size: 12px
}

.bookDetailsFooter .btnCancel {
    width: auto;
    padding: 10px 20px;
    color: var(--bg-primary);
    border-radius: 6px;
    border: none;
    font-size: 12px
}

.chatbotIconWrapper {
    height: 50px;
    width: 50px;
    border-radius: 50%;
    bottom: 2%;
    cursor: pointer;
    justify-content: center;
    align-items: center
}

.chatClosed {
    box-shadow: -10px 4px 42px 0 rgba(0, 0, 0, .75)
}

.chatOpened,
.chatOpened:hover {
    box-shadow: 0 11px 40px 0 #44444463
}

.chatOpened {
    background-color: #fff
}

.chatbotIconWrapper.chatOpened:hover .closeIcon {
    color: #fff
}

.chatbox {
    bottom: 11%;
    border-radius: 15px;
    box-shadow: 0 20px 40px 0 #2f2e2e40;
    z-index: 9999;
    height: 450px;
    width: 350px;
    flex-direction: column
}

.avatarWrapper img,
.backdrop {
    height: 100%;
    width: 100%
}

.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    overflow: hidden;
    opacity: 1;
    z-index: 9998;
    background-color: rgba(0, 0, 0, .6)
}

.chatbotHeader {
    display: flex;
    align-items: center;
    gap: 8px;
    background: var(--bg-primary);
    height: 13%;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    padding: 5px 10px
}

.chatbotLogoWrapper {
    height: 40px;
    width: 40px;
    overflow: hidden;
    padding: 3px;
    border: 1px solid #fff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center
}

.chatbox .footer,
.shades {
    align-items: center;
    display: flex
}

.chatbotLogoWrapper img {
    object-fit: contain
}

.onlineDot {
    height: 8px;
    width: 8px;
    background-color: green;
    border-radius: 50%
}

.chatbox .chats {
    height: 75%;
    max-height: 75%;
    background: #fff;
    opacity: 100%
}

.chatbox .footer {
    height: 12%;
    background-color: #fff;
    padding: 0 10px;
    justify-content: space-between
}

.chatbox .footer input {
    width: 100%;
    outline: 0;
    border: none;
    resize: none
}

.chatbotAvatarWrapper {
    height: 35px;
    width: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: #edebf1;
    padding: 3px;
    overflow: hidden
}

.chatBotSentMessages,
.chatboxRecievedMessages {
    padding: 6px 5px;
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
    font-size: 15px;
    height: auto
}

.chatbotAvatarWrapper img {
    height: 100%;
    width: 100%;
    object-fit: contain
}

.chatBotSentMessages {
    background-color: var(--bg-primary);
    border-bottom-left-radius: 15px;
    color: #fff
}

.chatboxRecievedMessages {
    background-color: #edebf1 !important;
    border-bottom-right-radius: 15px;
    color: #241763
}

.userGenInfoWrapper,
.userProfileWrapper {
    background-color: #fff;
    box-shadow: 0 20px 40px 0 #5d5d5d14;
    border-radius: 30px;
    height: auto
}

.userProfileWrapper .avatarWrapper {
    position: relative;
    height: 70px;
    width: 70px;
    padding: 5px;
    border: 2px solid #f6f6f6;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center
}

.editAvatarImage {
    position: absolute;
    background-color: var(--bg-primary);
    height: 30px;
    width: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    right: -7px;
    padding: 5px;
    border: 2px solid #fff;
    bottom: 2px;
    cursor: pointer
}

.select-bg {
    color: #aeb6bd
}

.input-gender::placeholder {
    color: #c7cdd2
}

.input-gendertwo::placeholder {
    color: #c7cdd2
}

.input-birthday {
    margin-top: 36px;
    height: 48px;
    color: #c7cdd2
}

.drop-btn {
    background-color: #f9fafb !important;
    width: 42px;
    height: 35px;
    border: 1px solid #a8acb166
}

.Salary_Heading,
.btn-setting {
    background-color: var(--bg-primary)
}

.input-cx,
.input-gender,
.input-gendertwo {
    width: 100%;
    border: 1px solid #a8acb166;
    outline: 0
}

.input-cx,
.input-gender {
    height: 35px;
    color: #c7cdd2
}

.input-gendertwo {
    height: 46px;
    color: #a8acb1
}

.input-height {
    margin-top: 38px
}

.icon-drop {
    color: #a8acb1
}

.btn-setting {
    width: 208px;
    height: 48px;
    color: #fff;
    border-radius: 24px;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center
}

.Exam_style,
.setting-para {
    color: #7a7a7a
}

.Salary_card {
    padding: 15px;
    max-height: 85vh;
    overflow: auto
}

.Salary_Report {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    column-gap: 20px
}

.Salary_Report .Student_Data {
    gap: 50px;
    display: flex
}

.Exam_style {
    font-size: 14px
}

.font_color,
.generateAtt button {
    font-weight: 700
}

.Salary_Data,
.Salary_Heading {
    border: 1px solid #ddd;
    padding: 5px
}

.exact_time,
.table th:last-child {
    border-right: hidden
}

.Salary_Data {
    font-size: 12px
}

.studentInfo {
    font-size: 15px
}

.Salary_Heading {
    font-size: 14px;
    color: #ddd
}

tr:nth-child(2n) {
    background-color: var(--bg-primary) 1a
}

.Table_Card {
    margin-top: 20px;
    border-radius: 10px;
    overflow-x: auto
}

.Report_style {
    background-color: #edebf1;
    font-family: PoppinsReg;
    padding: 20px 0;
    font-weight: 700;
    font-size: 20px
}

.Report_button {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin-top: 25px
}

.Time_Table {
    background-color: #fff;
    border-radius: 15px
}

.center_calender,
.left_angle,
.right_angle {
    background-color: #f4f4f5
}

.table .Shedule {
    width: 250px;
    border-left: hidden
}

.table {
    margin-top: 50px;
    border-top: hidden
}

.table td,
.table th {
    padding: .75rem;
    border-left: none;
    vertical-align: top;
    border-top: 1px solid #dee2e6;
    width: 144px;
    height: 72px
}

.table td:last-child {
    border-right: none
}

.exact_time {
    border-bottom: hidden
}

.left_angle,
.right_angle {
    height: 30px;
    width: 30px;
    cursor: pointer
}

.shades {
    list-style-type: none;
    gap: 30px
}

.text_color {
    font-size: 19px
}

.Year_table {
    background-color: var(--bg-primary);
    color: #fff;
    border-radius: 5px;
    width: 60px
}

.seven-class {
    background-color: #52afa626;
    color: #30ada1;
    border-radius: 8px
}

.section p,
.seven-class p {
    font-size: 12px;
    font-weight: 700
}

.section {
    background-color: #4e97ea26;
    color: #3d87dc;
    border-radius: 7px
}

.text-class {
    color: #979797;
    font-size: 12px;
    font-weight: lighter
}

.btn-class {
    width: 200px;
    height: 40px;
    background-color: #edebf1;
    color: var(--bg-primary);
    border-radius: 10px;
    border: none;
    font-size: 12px;
    font-weight: 700
}

.english-para {
    font-size: 13px;
    color: #979797;
    font-style: italic
}

.img-inbox {
    width: 30px;
    height: 30px;
    border-radius: 6px
}

.inbox-text {
    font-size: 13px;
    font-weight: 700
}

.inbox-para {
    font-size: 10px;
    color: #636363
}

.inbox-time {
    font-size: 9px;
    color: #afb8cf
}

.msg:hover {
    background-color: #edebf1cc;
    cursor: pointer
}

.textarea {
    width: 100%;
    height: 100px;
    outline: 0;
    border: 1px solid #a8acb166;
    color: #c7cdd2
}

.textarea::placeholder {
    color: #c7cdd2
}

.btn-update {
    width: 164px;
    height: 40px;
    background-color: var(--bg-primary);
    color: #fff;
    border: none;
    border-radius: 6px
}

.btn-cancel {
    background-color: #edebf1;
    color: var(--bg-primary);
    width: 120px;
    height: 40px;
    border: none;
    border-radius: 6px;
    font-weight: 700
}

.btnWithIcon {
    padding: 4px 8px;
    border-radius: 8px;
    color: #fff;
    width: auto;
    border: none
}

.activecurriculumNavItems,
.curriculumNavItems {
    font-size: 14px;
    font-weight: 600;
    border-radius: 7px;
    white-space: nowrap
}

.curriculumNavWrapper {
    box-shadow: 0 20px 40px 0 #5d5d5d14
}

.activecurriculumNavItems,
.curriculumNavItems:hover {
    background-color: #edebf1;
    cursor: pointer
}

.curriculumContent {
    font-size: 15px;
    max-height: 100vh;
    overflow-y: auto;
    height: 70vh
}

.poBtn,
.poBtnActive,
.ppBtn,
.ppBtnActive {
    height: 40px;
    width: 40px;
    outline: 0
}

.ppBtnActive {
    background-color: #3dc079;
    color: #fff;
    border: none;
    border-radius: 8px
}

.ppBtn {
    background-color: #fff;
    color: #3dc079;
    border-radius: 8px;
    border: 1px solid #3dc079
}

.poBtnActive {
    background-color: #3d87dc;
    color: #fff;
    border: none;
    padding: 10px;
    border-radius: 8px
}

.poBtn {
    background-color: #fff;
    color: #3d87dc;
    border-radius: 8px;
    border: 1px solid #3d87dc
}

.aBtnActive {
    background-color: #f2335d;
    color: #fff;
    border: none;
    outline: 0;
    height: 40px;
    width: 40px;
    border-radius: 8px
}

.aBtn,
.lBtn {
    border-radius: 8px;
    width: 40px;
    height: 40px;
    outline: 0
}

.aBtn {
    background-color: #fff;
    color: #f2335d;
    border: 1px
}

.BtnActive {
    background-color: #ffb930;
    color: #fff;
    border: none;
    outline: 0;
    height: 40px;
    width: 40px;
    border-radius: 8px
}

.lBtn {
    background-color: #fff;
    color: #ffb930;
    border: 1px
}

.lBtnActive {
    background-color: #ffb930;
    color: #fff;
    padding: 10px;
    border-radius: 8px;
    border: none;
    outline: 0;
    height: 40px;
    width: 40px
}

.rounded-borders,
select option {
    background-color: transparent
}

.attendanceUser img {
    height: 24px;
    width: 24px;
    border-radius: 50%;
    object-fit: cover
}

.dateInputWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #e4e7eb;
    padding: 0 3px;
    border-radius: 7.2px;
    width: 100%;
    height: 40px
}

.DatePicker {
    border: none !important;
    height: 100%;
    flex: 1
}

.DatePicker:focus {
    border: none !important;
    outline: 0 !important
}

.iconbox {
    height: 100%;
    width: 30px;
    border-left: 1px solid #e4e7eb;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer
}

.rounded-borders {
    border-top-left-radius: 7px !important;
    border-top-right-radius: 7px
}

.observationModalheader {
    border-top-right-radius: 7px;
    height: 100%;
    border-top-left-radius: 7px
}

.childChip,
.childChipActive {
    width: 200px;
    padding: 20px 9px;
    border-radius: 15px
}

.childChip {
    color: var(--bg-primary);
    background-color: #fff
}

.childChipActive {
    background: radial-gradient(circle, #585085, #241763);
    color: #fff;
    border: .8px solid #2417634d
}

.table100 .ps__rail-y .ps__thumb-y::before,
.table100 .ps__rail-y::before {
    content: "";
    display: block;
    position: absolute;
    border-radius: 5px;
    height: calc(100% - 30px);
    top: 15px;
    left: 0
}

.childChip h6,
.childChipActive h6 {
    font-weight: 400 !important;
    font-size: 14px !important
}

.childChip span {
    color: var(--bg-primary)
}

.childChipActive span {
    color: #aaa9a9
}

.childAvatar {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    overflow: hidden
}

.childAvatar img {
    height: 100%;
    width: 100%;
    border-radius: 50%;
    object-fit: cover
}

.upComingClassMain {
    background-color: #fff;
    padding: 10px
}

.column0,
.column1 {
    padding-left: 15px
}

.upComingHeading {
    font-size: 17px !important;
    margin-left: 5px
}

.clock_Dp {
    display: flex;
    justify-content: space-between;
    align-items: center
}

.upcomingClassCardImage {
    width: 30px;
    height: 30px;
    border-radius: 50%
}

.upcomingClassChips .test:first-child {
    background-color: #ede7ff;
    color: #8d66fe
}

.upcomingClassChips .test:nth-child(2) {
    color: #30ada1;
    background-color: #e5f3f2
}

.upcomingClassChips .test:nth-child(3) {
    color: #3d87dc;
    background-color: #e4effc
}

.test {
    font-size: 11px;
    font-weight: 700;
    border-radius: 7px;
    white-space: nowrap
}

.userDots {
    height: 15px;
    width: 15px;
    border-radius: 50%
}

::-webkit-scrollbar {
    width: 10px
}

::-webkit-scrollbar-track {
    background: #f1f1f1
}

::-webkit-scrollbar-thumb {
    background: #888
}

::-webkit-scrollbar-thumb:hover {
    background: #555
}

.row100 th {
    font-weight: 700 !important;
    color: var(--bs-heading-color)
}

.navbarWrapper h4,
.userName h6 {
    font-family: PoppinsSemiBold;
    color: #241763
}

.navbarWrapper h4 {
    font-size: 24px;
    font-weight: 700
}

.userName h5 {
    font-size: 12px;
    font-family: PoppinsSemiBold;
    font-weight: 400
}

.userName h6 {
    font-size: 15px;
    font-weight: 600
}

.text-container {
    max-height: 2.5em;
    overflow: hidden
}

.text-container p {
    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    line-clamp: 2;
    font-size: 12px
}

.CourseThumbnail {
    width: 100%;
    height: 150px
}

.custom-checkbox .custom-control-input:checked~.custom-control-label::before,
select option:hover {
    background-color: var(--bg-primary) !important
}

.table100.ver1 .ps__rail-y .ps__thumb-y::before,
.table100.ver2 .ps__rail-y .ps__thumb-y::before {
    background-color: #ccc
}

.custom-checkbox .custom-control-input:checked:focus~.custom-control-label::before {
    box-shadow: 0 0 0 1px #fff, 0 0 0 .2rem rgba(70, 60, 119, .25)
}

.custom-checkbox .custom-control-input:focus~.custom-control-label::before {
    box-shadow: 0 0 0 1px #fff, 0 0 0 .2rem rgba(0, 0, 0, .25)
}

.addButtonSty .lucide-plus,
.lucide-chevron-right,
.lucide-plus {
    width: 20px
}

.blackLogos {
    width: 80px;
    height: 50px
}

.PositionFixedH {
    max-height: 12vh;
    position: fixed;
    top: 0;
    z-index: 99
}

.js-pscroll,
.table100 {
    position: relative
}

.addButtonSty {
    background-color: #6f6795
}

.bgColor {
    background-color: #241763 !important;
    color: #fff !important
}

.Stars {
    --percent: calc(var(--rating) / 5 * 100%);
    display: inline-block;
    font-size: var(--star-size);
    font-family: Times;
    line-height: 1
}

.Stars::before {
    content: "★★★★★";
    background: linear-gradient(90deg, var(--star-background) var(--percent), var(--star-color) var(--percent));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent
}

.studentProfileHeading .col-md-2 label,
.studentProfileHeading .col-md-3 label,
.studentProfileHeading .col-md-6 label {
    font-size: 16px;
    font-weight: 500
}

.table100 .ps__rail-y {
    width: 9px;
    background-color: transparent;
    opacity: 1 !important;
    right: 5px
}

.table100 .ps__rail-y::before {
    background-color: var(--bg-primary);
    width: 100%
}

.table100 .ps__rail-y .ps__thumb-y {
    width: 100%;
    right: 0;
    background-color: transparent;
    opacity: 1 !important
}

.table100 .ps__rail-y .ps__thumb-y::before {
    background-color: #ccc;
    width: 100%
}

.table100.ver1 .ps__rail-y::before,
.table100.ver2 .ps__rail-y::before {
    background-color: var(--bg-primary)
}

.limiter {
    margin: 0 auto
}

.stylesUnSelcBtn {
    background-color: transparent;
    color: #241763;
    border: 1px solid #241763
}

.dropdown-button,
.flagsDropdown,
.table100 {
    background-color: #fff
}

.stylesUnSelcBtn:hover {
    background-color: #241763
}

.container-table100 {
    width: 100%;
    background: #fff;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    flex-wrap: wrap
}

.table100 {
    padding-top: 60px
}

td,
th {
    font-weight: unset;
    padding-right: 10px
}

.column0 {
    width: 4%
}

.column1 {
    width: 18.5%
}

.dropdown-button,
.flagList {
    padding: 10px;
    align-items: center;
    cursor: pointer
}

.column2,
.column3,
.column4,
.column5,
.column6 {
    width: 13.5%
}

.column7 {
    width: 10%;
    text-align: end
}

.table100-head th {
    padding-top: 18px;
    padding-bottom: 18px
}

.table100-body td {
    padding-top: 16px;
    padding-bottom: 16px
}

.table100-head {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0
}

.table100.ver1 th {
    font-size: 18px;
    color: #fff;
    line-height: 1.4;
    background-color: #6c7ae0
}

.table100.ver1 td,
.table100.ver2 td,
.table100.ver4 td,
.table100.ver5 td {
    font-size: 15px;
    color: grey;
    line-height: 1.4
}

.table100.ver1 .table100-body tr:nth-child(2n) {
    background-color: #f8f6ff
}

.table100.ver1,
.table100.ver2 {
    border-radius: 10px;
    box-shadow: 0 0 40px 0 rgba(0, 0, 0, .15);
    -moz-box-shadow: 0 0 40px 0 rgba(0, 0, 0, .15);
    -webkit-box-shadow: 0 0 40px 0 rgba(0, 0, 0, .15);
    -o-box-shadow: 0 0 40px 0 rgba(0, 0, 0, .15);
    -ms-box-shadow: 0 0 40px 0 rgba(0, 0, 0, .15)
}

.table100.ver1 .ps__rail-y,
.table100.ver2 .ps__rail-y,
.table100.ver3 .ps__rail-y {
    right: 5px
}

.table100.ver2 .table100-head {
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, .1);
    -moz-box-shadow: 0 5px 20px 0 rgba(0, 0, 0, .1);
    -webkit-box-shadow: 0 5px 20px 0 rgba(0, 0, 0, .1);
    -o-box-shadow: 0 5px 20px 0 rgba(0, 0, 0, .1);
    -ms-box-shadow: 0 5px 20px 0 rgba(0, 0, 0, .1)
}

.table100.ver2 th {
    font-size: 18px;
    color: #fa4251;
    line-height: 1.4;
    background-color: transparent
}

.table100.ver2 .table100-body tr,
.table100.ver4 .table100-body tr {
    border-bottom: 1px solid #f2f2f2
}

.table100.ver3 {
    background-color: #393939;
    border-radius: 10px;
    box-shadow: 0 0 40px 0 rgba(0, 0, 0, .15);
    -moz-box-shadow: 0 0 40px 0 rgba(0, 0, 0, .15);
    -webkit-box-shadow: 0 0 40px 0 rgba(0, 0, 0, .15);
    -o-box-shadow: 0 0 40px 0 rgba(0, 0, 0, .15);
    -ms-box-shadow: 0 0 40px 0 rgba(0, 0, 0, .15)
}

.table100.ver3 th {
    font-size: 15px;
    color: #00ad5f;
    line-height: 1.4;
    text-transform: uppercase;
    background-color: #393939
}

.table100.ver3 td {
    font-size: 15px;
    color: grey;
    line-height: 1.4;
    background-color: #222
}

.table100.ver4 th,
.table100.ver5 th {
    line-height: 1.4;
    background-color: transparent
}

.table100.ver3 .ps__rail-y::before {
    background-color: #4e4e4e
}

.table100.ver3 .ps__rail-y .ps__thumb-y::before {
    background-color: #00ad5f
}

.table100.ver4 .ps__rail-y .ps__thumb-y::before,
.table100.ver5 .ps__rail-y .ps__thumb-y::before {
    background-color: #ccc
}

.table100.ver4 {
    margin-right: -20px
}

.table100.ver4 .table100-body,
.table100.ver4 .table100-head {
    padding-right: 20px
}

.table100.ver4 th {
    font-size: 18px;
    color: #4272d7;
    border-bottom: 2px solid #f2f2f2
}

.table100.ver4 .column1 {
    padding-left: 7px
}

.table100.ver4 .ps__rail-y,
.table100.ver5 .ps__rail-y {
    right: 0
}

.table100.ver4 .ps__rail-y::before {
    background-color: var(--bg-primary)
}

.table100.ver5 .table100-body,
.table100.ver5 .table100-head {
    padding: 0 10px
}

.table100.ver5 th {
    font-size: 14px;
    color: #555;
    text-transform: capitalize
}

.table100.ver5 .table100-body tr {
    border-bottom: 10px solid #fff;
    border-radius: 10px
}

.table100.ver5 .table100-body table {
    border-collapse: separate;
    border-spacing: 0 10px
}

.table100.ver5 .table100-body td {
    border: 1px solid transparent;
    border-style: solid none;
    padding-top: 2px;
    padding-bottom: 2px
}

.table100.ver5 .table100-body td:first-child {
    border-left-style: solid;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px
}

.table100.ver5 .table100-body td:last-child {
    border-right-style: solid;
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px
}

.table100.ver5 tr:hover td:not(:last-child) {
    background-color: var(--bg-primary);
    color: #fff;
    cursor: pointer
}

.row100.body:hover .iconWrapper {
    background-color: #5c5387;
    color: #fff
}

.table100.ver5 tr:hover td:last-child {
    background-color: var(--bg-primary);
    cursor: pointer
}

.table100.ver5 .table100-head th {
    padding-top: 25px;
    padding-bottom: 25px
}

.table100.ver5 .ps__rail-y::before {
    background-color: var(--bg-primary)
}

.table100.ver5 .table100-body tr:nth-child(odd) {
    background: #f9f7ff !important
}

.table100.ver5 .table100-body tr:nth-child(2n) {
    outline: rgb(205, 205, 205) solid 1px !important
}

.navbarDropdown {
    position: relative;
    display: inline-block
}

.dropdown-button {
    gap: 10px;
    border: none;
    display: flex
}

.dropdown-button span,
.flag-language {
    margin-left: 10px
}

.flagsDropdown {
    position: absolute;
    top: 100%;
    left: 0;
    border: 1px solid #ccc;
    box-shadow: 0 8px 16px rgba(0, 0, 0, .2);
    z-index: 1;
    width: 200px;
    max-height: 200px;
    overflow-y: auto;
    display: flex;
    flex-direction: column
}

.flagList {
    display: flex
}

.flagList:hover {
    background-color: #f1f1f1
}

.alertNoti {
    display: flex;
    align-items: center;
    margin: 5px 0;
    padding: 12px;
    border-radius: 5px;
    font-family: 'Open Sans', sans-serif;
    font-size: .9rem;
    font-weight: 300;
    letter-spacing: 1px;
    position: relative
}

.alertNoti:hover {
    cursor: pointer
}

.alertNoti .icon-info {
    color: #00529b
}

.alertNoti .icon-warn {
    color: #9f6000
}

.alertNoti .icon-error {
    color: #d8000c
}

.alertNoti .icon-success {
    color: #4f8a10
}

.alertNoti .icon-default,
.alertNoti .icon-error,
.alertNoti .icon-info,
.alertNoti .icon-success,
.alertNoti .icon-warn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 10px
}

.alertNoti-info {
    background-color: #bde5f8;
    border: 1px solid #a5d4ec
}

.alertNoti-warn {
    background-color: #feefb3;
    border: 1px solid #e6db9d
}

.alertNoti-error {
    background-color: #ffbaba;
    border: 1px solid #e6a4a4
}

.alertNoti-success {
    background-color: #dff2bf;
    border: 1px solid #c7e1a9
}

.notificationScreen {
    height: 300px;
    overflow-y: auto
}

.noNotifications {
    font-size: 18px
}

.rotate180 {
    rotate: 180deg
}

.editModalLesson {
    max-height: 70vh;
    overflow-y: auto
}

.min-vh-82 {
    min-height: 82vh !important
}

.react-datepicker__day--in-range,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--keyboard-selected,
.react-datepicker__day--selected,
.react-datepicker__month-text--in-range,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__month-text--selected,
.react-datepicker__quarter-text--in-range,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__quarter-text--selected,
.react-datepicker__year-text--in-range,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--keyboard-selected,
.react-datepicker__year-text--selected {
    background-color: #473d78 !important;
    color: #fff !important
}

:where(.css-dev-only-do-not-override-apn68).ant-modal .ant-modal-content {
    padding: 0
}

:where(.css-dev-only-do-not-override-apn68).ant-modal .ant-modal-header {
    border-radius: 15px !important
}

:where(.css-dev-only-do-not-override-14i19y2).ant-modal .ant-modal-content {
    padding: 0
}

.react-datepicker-popper {
    z-index: 3
}

.w-85 {
    width: 82% !important
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
    background-image: none
}

.carousel-control-prev-icon::after {
    content: '<';
    color: #241763;
    font-size: 2rem
}

.carousel-control-next-icon::after {
    content: '>';
    color: #241763;
    font-size: 2rem
}

.carousel-control-next,
.carousel-control-prev {
    width: 2%
}

.image--cover {
    width: 110px;
    height: 110px;
    border-radius: 50%;
    object-fit: cover;
    border: 5px solid #fff
}

.imageCoverNav {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
    border: 2px solid #241763
}

.ant-picker-ok button,
:where(.css-dev-only-do-not-override-14i19y2).ant-btn-primary:not(:disabled):not(.ant-btn-disabled):hover {
    background-color: #241763
}

:where(.css-dev-only-do-not-override-14i19y2) a:hover {
    color: #241763
}

:where(.css-14i19y2).ant-btn-primary,
:where(.css-dev-only-do-not-override-14i19y2).ant-btn-primary {
    background-color: #241763
}

:where(.css-14i19y2).ant-btn-primary:not(:disabled):not(.ant-btn-disabled):hover {
    background-color: #241763
}

:where(.css-dev-only-do-not-override-14i19y2).ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
    color: #241763;
    border-color: #241763
}

:where(.css-dev-only-do-not-override-14i19y2).ant-btn:not(:disabled):focus-visible {
    outline: 0
}

.ant-modal-confirm div .ant-modal-content {
    padding: 20px
}

.modalBody {
    width: 100% !important;
    max-height: 60vh;
    overflow-y: auto;
    overflow-x: hidden
}

.uploadOutline {
    border: .5px solid #e1d8d8;
    border-radius: 5px;
    padding: 10px 7px
}

.gradeListSty:hover {
    background-color: none !important
}

:where(.css-dev-only-do-not-override-14i19y2).ant-modal .ant-modal-header {
    padding: 10px
}

.ant-modal-footer {
    padding: 10px !important
}

.text-primary-600 {
    color: #241763
}

.myTaskHeight {
    height: 40vh;
}

.myTaskHeightParents {
    height: 30vh;
}

.myTaskHeightStudent {
    height: 52vh;
}

.w-90 {
    width: 90% !important
}

.paginationStyle button {
    padding: .5rem 1.5rem !important
}

@media (max-width:990px) {
    .weakform-text {
        font-size: 14px !important
    }
}

@media (max-width:768px) {
    .weakform-text {
        font-size: 12px !important
    }
}

@media (max-width:1024px) {
    .weakform-text {
        font-size: 13px !important
    }
}

@media (max-width:600px) {

    .sidebarOuterSm,
    .sidebarWrapperSm {
        display: none
    }

    .mneuIcon {
        display: block
    }

    .chatbox {
        margin: auto
    }
}

@media (max-width:993px) {
    .sidebarOuterLg {
        width: 28%
    }

    .sidebarWrapperLg {
        width: 22%
    }

    .sidebarOuterSm {
        width: 12%
    }

    .sidebarWrapperSm {
        width: 10%
    }
}

@media (min-width:600px) {

    .sidebarOuterLg,
    .sidebarOuterSm,
    .sidebarWrapperLg,
    .sidebarWrapperSm {
        display: flex !important
    }

    .miniSidebar,
    .mneuIcon {
        display: none
    }
}

@media (max-width:600px) {
    .courseTiles {
        width: 97%
    }

    .smFlexScreen {
        justify-content: center !important
    }

    .mt-md-3 {
        margin-top: 16px
    }

    .ReactModal__Content {
        width: 95% !important
    }
}

:where(.css-dev-only-do-not-override-14i19y2).ant-pagination .ant-pagination-item-active {
    background-color: #241763 !important;
    color: #fff;
    border-color: #241763 !important;
}
:where(.css-14i19y2).ant-pagination .ant-pagination-item-active{
    background-color: #241763 !important;
    color: #fff;
    border-color: #241763 !important;
}